import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducer';
import thunk from "redux-thunk";
import logger from "redux-logger";

const persistConfig = {
  key: 'currentUser',
  storage: storage,
  whitelist: ['currentUser'] // which reducer want to store
};

const middlewares = [thunk,]//logger]


const store = createStore(rootReducer, composeWithDevTools(
  applyMiddleware(...middlewares)
));

const persistor = persistStore(store);


export { persistor, store }
