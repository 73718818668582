const userInitialState = {
    currentUser: localStorage.getItem('currentUser') !== undefined && localStorage.getItem('currentUser') !== null && localStorage.getItem('currentUser') !== '' ? JSON.parse(localStorage.getItem('currentUser')) : undefined
}


const UserReducer = (state = userInitialState, { type, ...rest }) => {
    switch (type) {
        case 'set-user':
            return { ...state, ...rest }
        default:
            return state
    }
}

export default UserReducer
