const navInitialState = {
    sidebarShow: true
}

const navReducer = (state = navInitialState, { type, ...rest }) => {
    switch (type) {
        case 'set':
            return { ...state, ...rest }
        default:
            return state
    }
}

export default navReducer