const applicationInitialState = {
    loading: true,
    error: false,
}

const applicationStateReducer = (state = applicationInitialState, { type, ...rest }) => {
    switch (type) {
        case 'set-loading':
            return {
                ...state, loading: rest.loading
            }
        case 'set-error-message':
            return {
                ...state, error: true, loading: false
            }
        case 'remove-error-message':
            return {
                ...state, error: false, loading: false
            }
        default:
            return state
    }
}

export default applicationStateReducer