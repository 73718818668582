import { combineReducers } from 'redux'
import navReducer from './nav/navReducer'
import UserReducer from './user/userReducer'
import applicationStateReducer from './applicationState/applicationStateReducer'

const rootReducer = combineReducers({
    nav: navReducer,
    user: UserReducer,
    application: applicationStateReducer
})

export default rootReducer