// export function authHeader() {
//     // return authorization header with token
//     const currentUser = authenticationService.currentUserValue;
//     if (currentUser && currentUser.token) {
//         return { Authorization: `Bearer ${currentUser.token}` };
//     } else {
//         return {};   
//     }
// }

export function authHeader() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // console.log(currentUser);
    if (currentUser && currentUser.token.auth_token) {
        // return { 'x-access-token': currentUser.token.auth_token };
        return { Authorization: 'Bearer ' + currentUser.token.auth_token };
    } else {
        return {};
    }
}