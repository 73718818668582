import React, { Component } from 'react'
import { Route, Switch, Router } from 'react-router-dom'
import { history } from './_helpers';
import './scss/style.scss'



const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/auth/Login'))
const ForgotPassword = React.lazy(() => import('./views/auth/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./views/auth/ResetPassword'))

class App extends Component {

  // static getDerivedStateFromError(error) {

  // }

  render() {
    return (
      <Router history={history}>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password Page"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route exact path="/password-reset/:token" name="Reset Password" render={(props) => <ResetPassword {...props} />} />
            <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </Router>
    )
  }
}

export default App
